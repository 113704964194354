@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

html,
body {
  height: 100%;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  margin: 1rem;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ecf0f2;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #e3e4e4;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

.chat-box::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

.chat-box::-webkit-scrollbar-track {
  @apply bg-sky-50;
  /* color of the tracking area */
}

.chat-box::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

/* Added this due to some messages being cuttoff. This should probably be removed once messages system
   gets a refactor
*/
.scroll-bar-change {
  width: 100%;
  height: 100%;
}

.scroll-bar-change::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

.scroll-bar-change::-webkit-scrollbar-track {
  @apply bg-sky-50;
  /* color of the tracking area */
}

.scroll-bar-change::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

.accordion_line {
  clip-path: polygon(15% 50%, 15% 0, 50% 0, 50% 50%, 100% 50%, 100% 85%, 50% 85%, 15% 85%);
  background-color: black;
}

.type-1-color {
  background-color: #809bce !important;
}

.type-2-color {
  background-color: #95b8d1 !important;
}

.type-3-color {
  background-color: #b8e0d2 !important;
}

.type-4-color {
  background-color: #d6eadf !important;
}

.type-5-color {
  background-color: #eac4d5 !important;
}

.soft-warn-color {
  color: orange;
}

.soft-warn-bg {
  background-color: orange;
}

.warning-color {
  color: orangered;
}

.warning-bg {
  background-color: orangered;
}

.good-color {
  color: rgb(65, 139, 65);
}

.good-bg {
  background-color: rgb(65, 139, 65);
}

.selected-div {
  background-color: #ffffff;
}

mat-slider {
  width: 50px !important;
  min-width: 50px !important;
}

.filter-btn {
  border-radius: 5px;
  font-size: 12px;
  padding: 0px 10px;
  letter-spacing: 1px;
  background: rgb(224, 224, 224);
}

.folder-tab {
  border-radius: 5px;
  font-size: 12px;
  padding: 0px 10px;
  letter-spacing: 1px;
  background: rgb(224, 224, 224);
  border: #809bce solid 2px;
}

.active {
  color: white;
  background-color: #809bce;
}

.chat-box-left {
  justify-content: start;
}

.chat-box-right {
  justify-content: end;
  flex-direction: row-reverse;
}

.chat-box-out {
  @apply !bg-athena-bg-blue;
  align-items: end;
  @apply !text-white;
}

.chat-box-in {
  align-items: start;
  @apply !text-athena-bg-dark;
}

.portrait-left {
  flex-direction: row;
}

.selected-dark {
  @apply bg-athena-bg-dark-selected;
}

.mat-dialog-container {
  border-radius: 40px !important;
}

.mat-mdc-dialog-surface {
  border-radius: 25px !important;
  overflow-y: hidden;
  @apply !bg-sky-50;
}

.mat-icon {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

th:first-child {
  border-top-left-radius: 5px !important;
}

th:last-child {
  border-top-right-radius: 5px !important;
}

tbody > tr {
  @apply border-b-2;
  @apply border-athena-bg-light-gry;
}

tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 5px !important;
}

tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 5px !important;
}

.admin-drop-down-mat-menu {
  width: fit-content;
  height: fit-content;
}

.admin-drop-down-mat-menu > .mat-mdc-menu-content {
  padding: 0px !important;
}

.mat-mdc-menu-panel.admin-drop-down-mat-menu {
  max-width: fit-content !important;
  /* Override the max-width */
  max-height: fit-content !important;
  /* border-radius: 10px; */
}

.ngx-file-drop__drop-zone {
  @apply !p-5;
}

.ngx-file-drop__content {
  width: 100% !important;
  height: 100% !important;
}
