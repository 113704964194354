html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.ngx-file-drop__drop-zone {
  height: 300px !important;
  border-radius: 5px !important;
  border-color: black !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
}

.big-btn {
  border-radius: 5px;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 10px 30px;
  background: rgb(235, 235, 235);
}

.sml-btn {
  border-radius: 5px;
  font-size: 12px;
  padding: 0px 10px;
  letter-spacing: 1px;
  background: rgb(224, 224, 224);
}

.dnd-select-file-btn {
  position: relative;
  top: 135px;
}

.example-tree-invisible {
  display: none;
}

.top-nav-wrapper {
  background: rgb(164, 164, 164) !important;
}

.admin-ngx-file-drop > .ngx-file-drop__drop-zone {
  height: 100% !important;
}

.cloud-mat-icon {
  font-size: 50px !important;
  height: 50px !important;
  width: 50px !important;
}

.mat-mdc-text-field-wrapper {
  background: white !important;
  border: 1px solid rgb(156, 163, 175);
  border-radius: 0.375rem !important;
  height: 41.26px !important;
}

.mdc-line-ripple {
  display: none;
}

.mdc-form-field {
  height: 42px !important;
}

.mat-mdc-form-field-flex {
  height: 41.26px;
}

.mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
  padding: 0 0 14px !important;
  font-size: 13px;
  height: 41.26px !important;
}

.shadow-custom {
  box-shadow: 0px 0px 70px 4px rgba(0, 0, 0, 0.5) !important;
  /* Customize the shadow intensity here */
}

.ngx-file-drop__drop-zone {
  border: none !important;
}

.uploader-status-date-box > .mat-mdc-form-field {
  height: 30px;
  width: 140px;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}

.mat-mdc-form-field-flex {
  height: 20px;
}

.mat-mdc-icon-button {
  height: 35px;
  padding: 0px !important;
}

.mat-mdc-form-field-icon-suffix {
  top: 10px;
  left: 11px;
}

.uploader-status-date-box
  > .mat-mdc-form-field
  > .mat-mdc-text-field-wrapper
  > .mat-mdc-form-field-flex
  > .mat-mdc-form-field-icon-suffix {
  top: 4px;
  left: 16px;
}

.uploader-status-date-box
  > .mat-mdc-form-field
  > .mat-mdc-text-field-wrapper
  > .mat-mdc-form-field-flex
  > .mat-mdc-form-field-infix {
  bottom: 7px;
}

.select-box:hover {
  box-shadow: inset 1px 0px 0px 0px #17adce;
  box-shadow: inset 0px 1px 0px 1px #17adce;
  box-shadow: inset 0px 0px 1px 1px #17adce;
  box-shadow: inset 0px 0px 0px 1px #17adce;
}
